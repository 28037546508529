import React from 'react';
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import AdditionalInformation
    from "../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../components/shared/Footer/Footer";
import BackToSolutionsButton from "../../components/shared/ButtonBackTo/BackToSolutionsButton";
import DcodeMarkerAutomated from "../../components/sections/DcodeMarkerAutomated/DcodeMarkerAutomated";
import {getLink, getRoutes} from "../../components/shared/routes";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";


export default () => (<RedirectTo url={getLink([getRoutes().TechnologyDMASAutomated])}/>);

// const Dma = () => {
//     const routes = getRoutes();
//     const parentTitle = routes.Solutions.pageTitle;
//
//     return (
//         <Layout>
//             <SEO title={parentTitle}/>
//
//             <Breadcrumbs
//                 title={routes.SolutionsDMA.pageTitle}
//                 parentTitles={[parentTitle]}
//                 parentRoutes={[routes.Solutions]}
//             />
//
//             <DcodeMarkerAutomated/>
//
//             <BackToSolutionsButton/>
//
//             <AdditionalInformation/>
//
//             <Footer/>
//         </Layout>
//     );
// };
//
// export default Dma;
